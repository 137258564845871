import './styles/style.scss';
import './scripts/main';

import {servicesSectionAnimation} from  './components/servece/index'
import {MultiStepForm} from './components/contact_form_by_steps/index'
import {customSelectHandler} from './UI/form-controls/form-controls'
import {ResizeTextArea} from "./scripts/main";

window.ResizeTextArea = ResizeTextArea;
window.customSelectHandler = customSelectHandler;
window.MultiStepForm = MultiStepForm;
window.servicesSectionAnimation = servicesSectionAnimation;

