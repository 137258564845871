export const servicesSectionAnimation = () => {
    const services = document.querySelectorAll('.service-section .services');
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const service = entry.target;

                setTimeout(() => {
                    service.classList.add('active');

                    const optionsItems = service.querySelectorAll('.services__item__options');
                    optionsItems.forEach((item, index) => {
                        setTimeout(() => {
                            item.classList.add('active');
                        }, index * 200);
                    });

                }, entry.target.dataset.index * 600);

                observer.unobserve(service);
            }
        });
    }, options);

    services.forEach(service => {
        observer.observe(service);
    });

    let lastServiceVisible = false;

    function checkIfAllServicesVisible() {
        const allVisible = [...services].every(service => service.classList.contains('active'));
        if (allVisible && !lastServiceVisible) {
            lastServiceVisible = true;
            showIcons();
        }
    }

    function showIcons() {
        const iconWrappers = document.querySelectorAll('.service-section .icon-wrapper');
        iconWrappers.forEach((icon, index) => {
            setTimeout(() => {
                icon.classList.add('active');
            }, index * 400);
        });
    }

    const serviceObserver = new MutationObserver(checkIfAllServicesVisible);
    services.forEach(service => {
        serviceObserver.observe(service, { attributes: true, attributeFilter: ['class'] });
    });
}
