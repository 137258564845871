/**********Sticky header ************ */
document.addEventListener("DOMContentLoaded", onDOMReady);
function onDOMReady() {
    let menuHeader = document.querySelector('header');
    let body = document.querySelector('body');
    if(menuHeader && body) {
        const scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
        window.addEventListener('scroll', onWindowScroll);
        function onWindowScroll() {
            if(window.document.scrollingElement.scrollTop > 120 && scrollHeight > window.innerHeight + 200){
                menuHeader.classList.add("sticky");
                body.classList.add("sticky-margin-top");
            } else {
                body.classList.remove("sticky-margin-top");
                menuHeader.classList.remove("sticky")
            }
        }
    }
}
/* **** Main menu **** */
function mainMenuHandler() {
    const mainMenu = document.querySelector('.main-menu');
    if (!mainMenu) return;
    const menuItems = mainMenu.querySelectorAll('.main-menu__list__item');
    for(let i = 0; i < menuItems.length; i++) {
        if (menuItems[i].classList.contains('current')) break;
        menuItems[i].classList.add('selected');
    }
}
mainMenuHandler();

/* **** Auto resize textarea field **** */
export class ResizeTextArea {
    constructor() {
        this.textarea = document.querySelector('textarea');
        this.rows = this.textarea.getAttribute('rows');
        this.textarea.addEventListener('input', this.autosize.bind(this));
    }

    autosize() {
        const previousRows = this.rows;
        const lines = this.textarea.value.split('\n').length;
        const characters = this.textarea.value.length;
        const rows = Math.max(lines, Math.ceil(characters / this.textarea.cols));
        if (rows !== previousRows) {
            this.textarea.setAttribute('rows', rows);
            this.rows = rows;
            this.textarea.dispatchEvent(new Event('change'));
        }
    }
}

/* **** Rules adding margin after blocks **** */
function marginRules() {
    const sections = document.querySelectorAll('section.product-description-section');
    const productCatalog = document.querySelectorAll('div.product-catalog');
    if(sections && sections.length > 0) {
        sections.forEach(section => {
            if (section.nextElementSibling && section.nextElementSibling.tagName.toLowerCase() === 'div') {
                section.classList.add('has-div');
            }
        });
    }
    if(productCatalog && productCatalog.length > 0) {
        productCatalog.forEach(div => {
            if (div.nextElementSibling && div.nextElementSibling.tagName.toLowerCase() !== 'section') {
                div.classList.add('not-has-section');
            }
        });
    }
}
marginRules();

/* **** Burger **** */
const burgerHandler = () => {
    if (!document.querySelector('.burger-menu')) {
        return;
    }

    const burgerBtn = document.querySelector('.burger-menu');
    const menu = document.querySelector('.main-menu');
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    let scrollPosition = 0;

    const handleMenu = () => {
        burgerBtn.classList.toggle('show');
        menu.classList.toggle('show');
        body.classList.toggle('show');

        const btn = document.querySelector('.main-menu.show a.btn');
        const mainMenuBtn = document.querySelectorAll('.main-menu.show a');

        if (btn) {
            btn.addEventListener('click', closeMenu);
        }

        if (mainMenuBtn.length) {
            mainMenuBtn.forEach(menuBtn => {
                menuBtn.addEventListener('click', closeMenu);
            });
        }

        if (html.classList.contains('show')) {
            html.classList.remove('show');
            window.scrollTo({
                top: scrollPosition,
                behavior: 'instant',
            });
        } else {
            scrollPosition = window.scrollY;
            html.classList.add('show');
        }
    };

    const closeMenu = () => {
        burgerBtn.classList.remove('show');
        menu.classList.remove('show');
        body.classList.remove('show');
        html.classList.remove('show');
        window.scrollTo({
            top: scrollPosition,
            behavior: 'instant',
        });

        removeEventListeners();
    };

    const removeEventListeners = () => {
        const btn = document.querySelector('.main-menu a.btn');
        const mainMenuBtn = document.querySelectorAll('.main-menu a');

        if (btn) {
            btn.removeEventListener('click', closeMenu);
        }

        if (mainMenuBtn.length) {
            mainMenuBtn.forEach(menuBtn => {
                menuBtn.removeEventListener('click', closeMenu);
            });
        }
    };

    burgerBtn.addEventListener('click', handleMenu);

    window.addEventListener('unload', removeEventListeners);
};

burgerHandler();
