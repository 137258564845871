export const customSelectHandler = () => {
    const customSelect = document.querySelector('.custom-select');
    const trigger = customSelect.querySelector('.custom-select__trigger');
    const options = customSelect.querySelectorAll('.custom-option');
    const hiddenInput = document.querySelector('#custom-select');

    // Toggle dropdown
    trigger.addEventListener('click', function () {
        customSelect.classList.toggle('open');
    });

    // Close the dropdown when clicking outside
    document.addEventListener('click', function (event) {
        if (!customSelect.contains(event.target)) {
            customSelect.classList.remove('open');
        }
    });

    // Handle option click
    options.forEach(option => {
        option.addEventListener('click', function () {
            hiddenInput.value = this.getAttribute('data-value');
            hiddenInput.dispatchEvent(new Event('input'));
            trigger.querySelector('span').textContent = this.textContent;
            customSelect.classList.remove('open');

            // Mark the selected option
            options.forEach(opt => opt.classList.remove('selected'));
            this.classList.add('selected');
        });
    });
}
