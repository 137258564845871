export class MultiStepForm {

    requiredField = 'input[aria-required="true"], textarea[aria-required="true"], .wpcf7-validates-as-required input'
    constructor(formSelector) {
        this.form = document.querySelector(formSelector);
        if (!this.form) {
            console.error('Form must be provided');
            return;
        }

        this.steps = this.form.querySelectorAll('.step-block');
        this.nextBtn = this.form.querySelector('#next-btn');
        this.prevBtn = this.form.querySelector('#prev-btn');
        this.submitBtn = this.form.querySelector('[type="submit"]');
        this.submitWrapper = this.form.querySelector('.submit-wrapper');
        this.currentStepEl = this.form.querySelector('.current-step');
        this.lastStepEl = this.form.querySelector('.last-step');
        this.mailIconEl = this.form.querySelector('.mail-icon');
        this.formrender = this.form.querySelector('.col-wrapper.form-render');
        this.successRender = this.form.querySelector('.col-wrapper.success');
        this.stepListDescr = this.form.querySelector('.step-list-description');
        this.successText = this.form.querySelector('.col-wrapper > .success-text');
        this.currentStep = 0;

        this.init();
    }

    init() {
        if (!this.steps.length) {
            console.error('Steps must be provided');
            return;
        }

        this.lastStepEl.textContent = this.steps.length;

        this.showStep(this.currentStep);
        this.addEventListeners();
        this.updateNextButtonState();
        this.afterSending();
        this.generateArithmeticProblem();
    }

    showStep(index) {
        this.steps.forEach((step, i) => {
            step.classList.toggle('active', i === index);
        });

        this.currentStepEl.textContent = index + 1;

        this.prevBtn.style.display = (index === 0) ? 'none' : 'inline-block';
        this.nextBtn.style.display = (index === this.steps.length - 1) ? 'none' : 'inline-block';
        this.submitBtn.style.display = (index === this.steps.length - 1) ? 'inline-block' : 'none';
        this.submitWrapper.style.display = (index === this.steps.length - 1) ? 'inline-block' : 'none';

        this.updateNextButtonState();
        this.moveMailIconEl(index);
        if (index === this.steps.length - 1) {
            this.generateArithmeticProblem();
        }
    }

    validateStep(index) {
        const inputs = this.steps[index].querySelectorAll(this.requiredField);
        let isValid = true;
        inputs.forEach(input => {
            if (!input.value.trim() || !input.checkValidity()) {
                isValid = false;
                input.classList.add('invalid');
            } else {
                input.classList.remove('invalid');
            }
        });

        if (index === this.steps.length - 1) {
            const answerInput = this.steps[index].querySelector('.answer input');
            if (answerInput.value.trim() !== '' && parseInt(answerInput.value.trim()) === this.currentAnswer) {
                isValid = true;
            } else {
                isValid = false;
                answerInput.classList.add('invalid');
            }
        }

        return isValid;
    }

    updateNextButtonState() {
        const isStepValid = this.validateStep(this.currentStep);
        this.nextBtn.disabled = !isStepValid;
        this.submitBtn.disabled = !isStepValid;
    }

    checkAutoFill() {
        const inputs = this.steps[this.currentStep].querySelectorAll(this.requiredField);
        inputs.forEach(input => {
            if (input.value.trim() !== '' && input.checkValidity()) {
                input.dispatchEvent(new Event('input'));
            }
        });

        this.updateNextButtonState();
    }

    addEventListeners() {
        this.nextBtn.addEventListener('click', () => {
            if (this.validateStep(this.currentStep)) {
                this.currentStep++;
                this.showStep(this.currentStep);
            }
        });

        this.prevBtn.addEventListener('click', () => {
            this.currentStep--;
            this.showStep(this.currentStep);
        });

        this.steps.forEach((step, index) => {
            const inputs = step.querySelectorAll(this.requiredField);
            inputs.forEach(input => {
                input.addEventListener('input', () => {
                    this.updateNextButtonState()
                });
            });
        });

        window.addEventListener('load', () => {
            this.checkAutoFill();
        });

        this.steps.forEach(step => {
            const inputs = step.querySelectorAll(this.requiredField);
            inputs.forEach(input => {
                input.addEventListener('focus', () => this.updateNextButtonState());
            });
        });
    }

    moveMailIconEl(index) {
        this.mailIconEl.setAttribute('data-step', index);
    }

    generateArithmeticProblem() {
        const firstNum = Math.floor(Math.random() * 10);
        const secondNum = Math.floor(Math.random() * 10);
        this.currentAnswer = firstNum + secondNum;

        const questionElement = this.steps[this.steps.length - 1].querySelector('.question');
        questionElement.innerHTML = `${firstNum} + ${secondNum} =`;
    }

    afterSending() {
        document.addEventListener( 'wpcf7mailsent', ( event ) => {
            this.formrender.classList.add('hidden');
            this.successRender.classList.add('show');
            this.stepListDescr.classList.add('v-hidden');
            this.successText.classList.add('show');
            this.moveMailIconEl(3);
            setTimeout(() => {
                this.formrender.classList.remove('hidden');
                this.successRender.classList.remove('show');
                this.stepListDescr.classList.remove('v-hidden');
                this.successText.classList.remove('show');
                this.currentStep = 0;
                this.showStep(0);
            }, 3500)
        }, false );

        document.addEventListener( 'wpcf7invalid', ( event ) => {
            console.log(event.target);
        }, false );
    }
}
